import { RefineCatalogsResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model'
interface MultiSelectMultiSelectComponentData {
  vehicles: {
    make: string
    years: {
      year: string
      models: {
        model: string
        components: string[]
      }[]
    }[]
  }[]
}
export function convertToMultiSelectComponentData(
  refineCatalogs: RefineCatalogsResponse[] | undefined,
): MultiSelectMultiSelectComponentData {
  const vehicles = refineCatalogs?.map((make) => convertMake(make)) || []
  return { vehicles }
}

function convertMake(make: RefineCatalogsResponse): { make: string; years: Array<any> } {
  return {
    make: make.label,
    years: (make.items || []).map((year) => convertYear(year)),
  }
}

function convertYear(year: any): { year: string; models: Array<any> } {
  return {
    year: year.label,
    models: (year.items || []).map((model: any) => convertModel(model)),
  }
}

function convertModel(model: any): { model: string; components: string[] } {
  return {
    model: model.label,
    components: (model.items || []).map((component: { label: any }) => component.label),
  }
}

export function getDetailsByYear(year: string, totalItems: RefineCatalogsResponse[] | undefined) {
  const covertedData = convertToMultiSelectComponentData(totalItems)

  const makes = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.filter((yearObj) => yearObj.year === year).map(() => vehicle.make),
      ),
    ),
  ]

  const models = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.flatMap((yearObj) =>
          yearObj.year === year ? yearObj.models.map((modelObj) => modelObj.model) : [],
        ),
      ),
    ),
  ]

  const components = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.flatMap((yearObj) =>
          yearObj.year === year ? yearObj.models.flatMap((modelObj) => modelObj.components) : [],
        ),
      ),
    ),
  ]

  return {
    makes,
    models,
    components,
    year,
  }
}

export function getDetailsByMake(makeName: string, totalItems: RefineCatalogsResponse[] | undefined) {
  const covertedData = convertToMultiSelectComponentData(totalItems)
  const years = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.make === makeName ? vehicle.years.map((yearObj) => yearObj.year) : [],
      ),
    ),
  ]

  const models = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.make === makeName
          ? vehicle.years.flatMap((yearObj) => yearObj.models.map((modelObj) => modelObj.model))
          : [],
      ),
    ),
  ]

  const components = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.make === makeName
          ? vehicle.years.flatMap((yearObj) =>
              yearObj.models.flatMap((modelObj) => modelObj.components),
            )
          : [],
      ),
    ),
  ]

  return {
    years,
    models,
    components,
    makeName,
  }
}

export function getDetailsByModel(modelName: string, totalItems: RefineCatalogsResponse[] | undefined) {
  const covertedData = convertToMultiSelectComponentData(totalItems)

  const makes = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.flatMap((yearObj) =>
          yearObj.models.filter((modelObj) => modelObj.model === modelName).map(() => vehicle.make),
        ),
      ),
    ),
  ]

  const years = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.flatMap((yearObj) =>
          yearObj.models.filter((modelObj) => modelObj.model === modelName).map(() => yearObj.year),
        ),
      ),
    ),
  ]

  const components = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.flatMap((yearObj) =>
          yearObj.models.flatMap((modelObj) =>
            modelObj.model === modelName ? modelObj.components : [],
          ),
        ),
      ),
    ),
  ]

  return {
    makes,
    years,
    components,
    modelName,
  }
}

export function getDetailsByComponent(componentName: string, totalItems: RefineCatalogsResponse[] | undefined) {
  const covertedData = convertToMultiSelectComponentData(totalItems)

  const makes = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.flatMap((yearObj) =>
          yearObj.models.flatMap((modelObj) =>
            modelObj.components.includes(componentName) ? vehicle.make : [],
          ),
        ),
      ),
    ),
  ]

  const years = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.flatMap((yearObj) =>
          yearObj.models.flatMap((modelObj) =>
            modelObj.components.includes(componentName) ? yearObj.year : [],
          ),
        ),
      ),
    ),
  ]

  const models = [
    ...new Set(
      covertedData.vehicles.flatMap((vehicle) =>
        vehicle.years.flatMap((yearObj) =>
          yearObj.models
            .filter((modelObj) => modelObj.components.includes(componentName))
            .map((modelObj) => modelObj.model),
        ),
      ),
    ),
  ]

  return {
    makes,
    years,
    models,
    componentName,
  }
}
