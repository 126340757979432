import { useEffect } from 'react';
import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';

interface SliderDataResponse {
    minPrice: number | null;
    maxPrice: number | null;
    onSaleProducts: boolean;
    newProducts: boolean;
    callForPricingProducts: boolean;
}

export const useSliderItemsData = (
    updatedFilterItems: IItemListResponse | undefined,
    isPriceRangeSelected: boolean,
    totalSearchItems: IItemListResponse | undefined,
    setPreviousMaxPrice: any,
    setPreviousMinPrice: any,
    previousMaxPrice: any,
    previousMinPrice: any
): SliderDataResponse => {
    let onSaleProducts = false;
    let newProducts = false;
    let callForPricing = false;

    updatedFilterItems?.newProductPromo?.forEach((promo) => {
        if (promo.key === 'On_Sale') {
            onSaleProducts = true;
        } else if (promo.key === 'New_Products') {
            newProducts = true;
        }
    });

    useEffect(() => {
        if (!isPriceRangeSelected) {
            setPreviousMinPrice(
                updatedFilterItems?.minPrice ??
                    previousMinPrice ??
                    totalSearchItems?.minPrice ??
                    null
            );
            setPreviousMaxPrice(
                updatedFilterItems?.maxPrice ??
                    previousMaxPrice ??
                    totalSearchItems?.maxPrice ??
                    null
            );
        }
    }, [updatedFilterItems, isPriceRangeSelected, totalSearchItems]);
    const minPrice = previousMinPrice;
    const maxPrice = previousMaxPrice;

    if (minPrice === 0) {
        callForPricing = true;
    }

    return {
        minPrice,
        maxPrice,
        newProducts,
        onSaleProducts,
        callForPricingProducts: callForPricing,
    };
};
