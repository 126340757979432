import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { ILeftCatalogItem } from 'srs.sharedcomponents/lib/esm/models/srs.leftCatalogItem.model';
import { stringToBoolean } from 'srs.sharedcomponents/lib/esm/utils/helpers';

import MultiSelectList from './FilterMenuComponent/MultiSelectList';
import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks';
import {
    allItems,
    selectFilterValue,
} from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice';

export interface ILeftMenuProps {
    items: ILeftCatalogItem[];
}

const LeftMenuComponent = ({ items }: ILeftMenuProps) => {
    const { search } = useLocation();
    const values = queryString.parse(search);
    const showedItems: IItemListResponse | undefined = useAppSelector(allItems);
    const [filteredItems, setFilteredItems] = useState<
        IItemListResponse | undefined
    >(undefined);
    const lastSearch = useAppSelector(selectFilterValue);
    useEffect(() => {
        if (showedItems) {
            setFilteredItems(showedItems);
        }
    }, [lastSearch]);

    const [categoryExpanded, setCategoryExpanded] = useState([0]);
    const menuCollapsedByDefault = stringToBoolean(
        process.env.REACT_APP_COLLAPSE_LEFT_MENU
    );

    /**
     * Toggle category list
     * @param id {Number} id of parent category
     */
    const toggleListWithCategory = (id: number) => {
        setCategoryExpanded(categoryExpanded.concat(id));
        if (categoryExpanded.includes(id)) {
            setCategoryExpanded(
                categoryExpanded.filter((value) => value !== id)
            );
        }
    };

    const validateCategorySelectedItem = () => {
        let selectedItem: ILeftCatalogItem | undefined;

        if (values.CatID) {
            for (const category of items) {
                if (category.items) {
                    selectedItem = category.items.find(
                        (item) => Number(values.CatID) === item.id
                    );
                    if (selectedItem) {
                        break;
                    }
                }
            }
        }
        return selectedItem;
    };

    const expandPreviousSelectedMainCategory = () => {
        if (menuCollapsedByDefault) {
            let selectedItem = validateCategorySelectedItem();

            if (selectedItem) {
                let parentId = Number(selectedItem.parentId);
                let selectedItemCategory = items.find(
                    (item) => parentId === item.id
                );

                if (selectedItemCategory) {
                    toggleListWithCategory(parentId);
                }
            }
        }
    };

    useEffect(() => {
        expandPreviousSelectedMainCategory();
    }, []);

    return (
        <div className="multiSelect-Class">
            <MultiSelectList
                totalSearchItems={filteredItems}
                updatedFilterItems={showedItems}
            />
        </div>
    );
};
export default LeftMenuComponent;
