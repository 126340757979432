import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faRemove,
} from '@fortawesome/free-solid-svg-icons';
import {
    convertToMultiSelectComponentData,
    getDetailsByComponent,
    getDetailsByMake,
    getDetailsByModel,
    getDetailsByYear,
} from './MultiSelectDataHandling';
import { CollapsibleSection } from './CollapsableSection';
import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';
import {
    selectProductFilters,
    selectSearchValue,
    selectVehicleApplicationClicked,
    updateisMultiSelectFiltersApplied,
    updateMultiSelectFilters,
} from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice';
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks';
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
import { useDispatch } from 'react-redux';
import { getColor } from './MultiSelectList';

export interface VehicleData {
    make: Record<string, string[]>;
    model: Record<string, string[]>;
    year: Record<string, string[]>;
    component: Record<string, string[]>;
}

interface SubFiltersMenuProps {
    totalItems: IItemListResponse | undefined;
    selectedValues: SelectedValues;
    setSelectedValues: React.Dispatch<React.SetStateAction<SelectedValues>>;
    updatedFilterItems: IItemListResponse | undefined;
    handleApplyFiltersButton: any;
    data: VehicleData;
    setData: any;
    setSearchedValueLabel: any;
}

const blueColor = 'rgb(14, 22, 100) !important';

const countSelectedCategoryItems = (
    items: { label: string; code: string; items?: any[] }[] | undefined,
    selectedCategories: string[],
    value?: string
): number => {
    if (!items || items.length === 0) {
        return 0;
    }

    return items.reduce((count, child) => {
        const isSelected = selectedCategories.includes(child.code) ? 1 : 0;
        const nestedCount = countSelectedCategoryItems(
            child.items,
            selectedCategories
        );
        return count + isSelected + nestedCount;
    }, 0);
};

export const ActionButton = ({
    onClick,
    children,
    buttonType,
}: {
    onClick: () => void;
    children: React.ReactNode;
    buttonType: string;
}) => (
    <Button
        style={{
            marginTop: 1,
            fontSize: '10px',
            fontWeight: '900',
            color: buttonType === 'selectAll' ? 'white' : 'rgb(14, 22, 100)',
            backgroundColor:
                buttonType === 'selectAll' ? 'rgb(14, 22, 100)' : 'white',
            border: '1px solid',
            borderColor: blueColor,
            padding: '5px',
            textTransform: 'capitalize',
            borderRadius: 5,
        }}
        onClick={onClick}
    >
        {children}
    </Button>
);

const CategorySection = ({
    selectedValues,
    expanded,
    items,
    handleCategorySelection,
    handleItemClick,
    toggleSection,
    handleSelectAllClearAllButtons,
    translate,
    expandedCategories,
    toggleShowMoreCategories,
}: {
    selectedValues: Record<string, string[]>;
    expanded: Record<string, boolean>;
    toggleSection: (label: string) => void;
    handleItemClick: any;
    items: {
        label: string;
        items: any[];
        code: string;
    }[];
    handleCategorySelection: any;
    handleSelectAllClearAllButtons: any;
    translate: any;
    expandedCategories: any;
    toggleShowMoreCategories: any;
}) => {
    const renderCategory = (category: {
        label: string;
        items: any[];
        code: string;
    }) => {
        const ITEMS_TO_SHOW = 6;
        const isExpanded = expandedCategories[category.label] || false;
        const visibleItems = isExpanded
            ? category.items
            : category.items.slice(0, ITEMS_TO_SHOW);
        return (
            <React.Fragment key={category.label}>
                <Box
                    sx={{
                        border: '1px solid gray',
                        borderRadius: '4px',
                        padding: '5px 10px',
                        width: 'auto',
                        textAlign: 'center',
                        flexBasis: '100%',
                        wordBreak: 'break-word',
                        backgroundColor:
                            countSelectedCategoryItems(
                                category.items,
                                selectedValues['category'],
                                category.label
                            ) > 0
                                ? '#066FEF'
                                : 'white',
                        cursor: 'pointer',
                        textTransform: 'none',
                        color: getColor(
                            countSelectedCategoryItems(
                                category.items,
                                selectedValues['category'],
                                category.label
                            ) > 0
                        ),
                    }}
                    id={`filterButton_${category.label.replaceAll(' ', '')}`}
                    onClick={() => toggleSection(category.label)}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                            width: '100%',
                            wordBreak: 'break-word',
                        }}
                    >
                        {countSelectedCategoryItems(
                            category.items,
                            selectedValues['category'],
                            category.label
                        ) > 0 && (
                            <Typography
                                sx={{
                                    border: '1px solid',
                                    borderRadius: '50%',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                }}
                            >
                                {countSelectedCategoryItems(
                                    category.items,
                                    selectedValues['category'],
                                    category.label
                                )}
                            </Typography>
                        )}

                        <Typography
                            id={`subcategoryMenuOption_${category.label.replaceAll(
                                ' ',
                                ''
                            )}`}
                        >
                            {category.label}
                        </Typography>
                        <FontAwesomeIcon
                            icon={
                                expanded[category.label]
                                    ? faChevronUp
                                    : faChevronDown
                            }
                        />
                    </Box>
                </Box>

                {expanded[category.label] && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: '10px',
                                marginLeft: 'auto',
                                gap: '10px',
                                flexWrap: 'wrap',
                                width: '100%',
                            }}
                        >
                            {(() => {
                                const { selectionState } =
                                    handleSelectAllClearAllButtons(
                                        category.code,
                                        items
                                    );
                                if (selectionState === 'no Button') {
                                    return null;
                                }
                                if (selectionState === 'allSelected') {
                                    return (
                                        <ActionButton
                                            onClick={() =>
                                                handleCategorySelection(
                                                    category.code,
                                                    false
                                                )
                                            }
                                            buttonType="clearAll"
                                        >
                                            {translate(`ltl_ClearAllText`)}{' '}
                                        </ActionButton>
                                    );
                                } else if (selectionState === 'someSelected') {
                                    return (
                                        <>
                                            <ActionButton
                                                onClick={() =>
                                                    handleCategorySelection(
                                                        category.code,
                                                        false
                                                    )
                                                }
                                                buttonType="clearAll"
                                            >
                                                {translate(`ltl_ClearAllText`)}{' '}
                                            </ActionButton>
                                            <ActionButton
                                                onClick={() =>
                                                    handleCategorySelection(
                                                        category.code,
                                                        true
                                                    )
                                                }
                                                buttonType="selectAll"
                                            >
                                                {translate('selectAll_txt')}
                                            </ActionButton>
                                        </>
                                    );
                                } else if (selectionState === 'noneSelected') {
                                    return (
                                        <ActionButton
                                            onClick={() =>
                                                handleCategorySelection(
                                                    category.code,
                                                    true
                                                )
                                            }
                                            buttonType="selectAll"
                                        >
                                            {translate('selectAll_txt')}
                                        </ActionButton>
                                    );
                                }
                            })()}
                        </Box>

                        <Box
                            sx={{
                                padding: '10px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                backgroundColor: 'white',
                                alignItems: 'flex-start',
                            }}
                        >
                            {visibleItems.map((child: any) => {
                                if (child.items && child.items.length > 0) {
                                    return renderCategory(child);
                                }

                                return (
                                    <Button
                                        key={String(child.label)}
                                        style={{
                                            border: '1px solid gray',
                                            borderRadius: '4px',
                                            padding: '5px 10px',
                                            width: 'auto',
                                            justifyContent: 'start',
                                            textAlign: 'left',
                                            flexBasis: '100%',
                                            wordBreak: 'break-word',
                                            backgroundColor: selectedValues[
                                                'category'
                                            ].includes(child.code)
                                                ? '#066FEF'
                                                : 'white',
                                            cursor: 'pointer',
                                            textTransform: 'capitalize',
                                            color: getColor(
                                                selectedValues[
                                                    'category'
                                                ].includes(child.code)
                                            ),
                                        }}
                                        id={`subcategoryMenuOption_${child.label}`}
                                        onClick={() =>
                                            handleItemClick(
                                                'category',
                                                child.code,
                                                undefined,
                                                category.code
                                            )
                                        }
                                        startIcon={
                                            selectedValues['category'].includes(
                                                String(child.code)
                                            ) ? (
                                                <FontAwesomeIcon
                                                    icon={faRemove}
                                                    size="xs"
                                                    style={{ fontSize: '10px' }}
                                                />
                                            ) : null
                                        }
                                    >
                                        {child.label}
                                    </Button>
                                );
                            })}
                        </Box>
                        {category.items.length > ITEMS_TO_SHOW && (
                            <Button
                                onClick={() =>
                                    toggleShowMoreCategories(category.label)
                                }
                                style={{
                                    marginTop: 1,
                                    fontSize: '10px',
                                    fontWeight: '900',
                                    color: blueColor,
                                    marginRight: 'auto',
                                }}
                            >
                                {isExpanded
                                    ? translate(`less_txt`)
                                    : translate(`more_txt`)}
                            </Button>
                        )}
                    </Box>
                )}
            </React.Fragment>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
            }}
        >
            {items.map((category) => renderCategory(category))}
        </Box>
    );
};

export const findParentCategory = (categories: any[], value: string): any => {
    for (const category of categories) {
        if (category.code === value) {
            return category;
        }
        if (
            category.items?.some(
                (item: { code: string }) => item.code === value
            )
        ) {
            return category;
        }

        if (category.items) {
            const found = findParentCategory(category.items, value);
            if (found) {
                return category;
            }
        }
    }
    return null;
};
const findCategoryChildrenByCode = (categories: any, code: string): any => {
    for (let category of categories) {
        if (category.code === code) {
            return category;
        }
        if (category.items && category.items.length > 0) {
            const found = findCategoryChildrenByCode(category.items, code);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

export function transformCategories(
    allCategories: any,
    selectedCategories: any,
    searchedCategoryLabel: string
) {
    const findCategoryByCode = (categories: any, code: string): any => {
        for (let category of categories) {
            if (
                category.items?.some(
                    (item: { code: string }) => item.code === code
                )
            ) {
                return category;
            }
            if (category.items && category.items.length > 0) {
                const found = findCategoryByCode(category.items, code);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const findCategoryHierarchy = (categories: any, code: any): any => {
        for (let category of categories) {
            if (category.code === code) {
                let result: { CategoryName: string; SubCategories: any[] } = {
                    CategoryName: category.label,
                    SubCategories: [],
                };
                if (category.items && category.items.length > 0) {
                    result.SubCategories =
                        buildHierarchy(category.items, selectedCategories) ||
                        [];
                }
                return result;
            }

            if (category.items) {
                const subCategoryHierarchy = findCategoryHierarchy(
                    category.items,
                    code
                );
                if (subCategoryHierarchy) {
                    return {
                        CategoryName: category.label,
                        SubCategories: [subCategoryHierarchy],
                    };
                }
            }
        }
        return null;
    };
    const extractCode = (item: { code: any }) => item.code;

    const extractCodesFromItem = (item: {
        items?: { code: string }[];
    }): string[] => {
        return item.items?.map((subItem) => subItem.code) || [];
    };

    const extractAllChildCodes = (parentCategory: {
        items?: { items?: { code: string }[] }[];
    }) => {
        return (
            parentCategory.items?.flatMap((item) =>
                extractCodesFromItem(item)
            ) || []
        );
    };
    const areAllChildrenSelected = (
        childCodes: any[],
        selectedCategories: string | any[]
    ) => {
        return childCodes.every((childCode: any) =>
            selectedCategories.includes(childCode)
        );
    };

    const buildHierarchy = (categories: any, selectedCategories: any[]) => {
        const result: any[] = [];
        const processedParents = new Set();
        const allParents = selectedCategories.map(
            (code: string) => findParentCategory(categories, code)?.label
        );
        if (
            !allParents.includes(searchedCategoryLabel) &&
            searchedCategoryLabel != ''
        ) {
            result.push({
                CategoryName: searchedCategoryLabel,
                SubCategories: [],
            });
        }

        selectedCategories.forEach((code: string) => {
            const handleCategoryAllSelection = () => {
                const parentCategory = findCategoryByCode(categories, code);
                if (!parentCategory) return;

                const parentCode = parentCategory.label;
                if (processedParents.has(parentCode)) return;

                const allChildCodes =
                    parentCategory.items?.map(extractCode) || [];
                const allChildrenSelected = areAllChildrenSelected(
                    allChildCodes,
                    selectedCategories
                );

                if (allChildrenSelected) {
                    addParentCategoryToResult(Codelevels, parentCategory);
                    processedParents.add(parentCode);
                } else {
                    addCategoryHierarchyToResult(categories, code);
                }
            };

            const addParentCategoryToResult = (
                Codelevels: string | any[],
                parentCategory: { label: any }
            ) => {
                if (Codelevels.length === 2) {
                    result.push({
                        CategoryName: parentCategory.label,
                        SubCategories: [],
                    });
                } else if (Codelevels.length === 3) {
                    result.push({
                        CategoryName: Codelevels[0],
                        SubCategories: [
                            {
                                CategoryName: parentCategory.label,
                                SubCategories: [],
                            },
                        ],
                    });
                }
            };

            const addCategoryHierarchyToResult = (
                categories: any,
                code: string
            ) => {
                const categoryHierarchy = findCategoryHierarchy(
                    categories,
                    code
                );
                if (categoryHierarchy) {
                    result.push(categoryHierarchy);
                }
            };

            const Codelevels = code.split('_');
            if (Codelevels.length == 2) {
                handleCategoryAllSelection();
            } else if (Codelevels.length === 3) {
                const parentCategory = findParentCategory(categories, code);

                if (parentCategory) {
                    if (processedParents.has(parentCategory.label)) return;
                    const parentCode = parentCategory?.label;
                    const allChildCodes = extractAllChildCodes(parentCategory);

                    const allChildrenSelected = allChildCodes.every(
                        (childCode: string) =>
                            selectedCategories.includes(childCode)
                    );

                    if (
                        allChildrenSelected &&
                        parentCode &&
                        !processedParents.has(parentCode)
                    ) {
                        result.push({
                            CategoryName: parentCategory.label,
                            SubCategories: [],
                        });
                        processedParents.add(parentCode);
                    } else {
                        handleCategoryAllSelection();
                    }
                }
            }
        });
        return result;
    };

    return buildHierarchy(allCategories, selectedCategories);
}

interface UpdateFiltersParams {
    dispatchFilters: any;
    onSale: boolean;
    newProduct: boolean;
    priceRange: number[];
    allCategories: any[];
    newSelectedValues: SelectedValues;
    callForPricing: boolean;
    updatedFilterItems: IItemListResponse | undefined;
    isPriceRangeSelected: boolean;
    priceRangeValues: any;
    searchedLabel: any;
}

export const updateFilters = async (
    params: UpdateFiltersParams,
    isVehicleApplicationClicked: boolean
) => {
    const {
        dispatchFilters,
        onSale,
        newProduct,
        priceRange,
        allCategories,
        newSelectedValues,
        callForPricing,
        updatedFilterItems,
        isPriceRangeSelected,
        priceRangeValues,
        searchedLabel,
    } = params;
    if (!isVehicleApplicationClicked) {
        await dispatchFilters(updateisMultiSelectFiltersApplied(false));
        await dispatchFilters(updateMultiSelectFilters(null));
        let minimumPrice = isPriceRangeSelected
            ? priceRangeValues[0]
            : updatedFilterItems?.minPrice;
        let maximumPrice = isPriceRangeSelected
            ? priceRangeValues[1]
            : updatedFilterItems?.maxPrice;
        if (
            Object.values(newSelectedValues).reduce(
                (acc, curr) => acc + curr.length,
                0
            ) !== 0 ||
            onSale ||
            newProduct ||
            callForPricing ||
            (priceRange[0] !== minimumPrice && priceRange[0] != -1) ||
            (priceRange[1] !== maximumPrice && priceRange[1] != 1000000)
        ) {
            dispatchFilters(
                updateMultiSelectFilters({
                    OnSale: onSale,
                    NewProduct: newProduct,
                    MinPrice: priceRange[0] == -1 ? 0 : priceRange[0],
                    MaxPrice: priceRange[1] == 1000000 ? 0 : priceRange[1],
                    Categories: transformCategories(
                        allCategories,
                        newSelectedValues.category,
                        searchedLabel.category
                    ),
                    Suppliers: newSelectedValues.supplier,
                    Makes: newSelectedValues.make,
                    Years: newSelectedValues.year,
                    Models: newSelectedValues.model,
                    Components: newSelectedValues.component,
                    callForPricing: callForPricing,
                })
            );
            dispatchFilters(updateisMultiSelectFiltersApplied(true));
        }
    }
};
export type SelectedValues = {
    make: string[];
    year: string[];
    model: string[];
    component: string[];
    supplier: string[];
    category: string[];
};

export const SubFiltersMenu: React.FC<SubFiltersMenuProps> = ({
    totalItems,
    selectedValues,
    setSelectedValues,
    updatedFilterItems,
    handleApplyFiltersButton,
    data,
    setData,
    setSearchedValueLabel,
}) => {
    const dispatch = useDispatch();
    const isVehicleApplicationClicked = useAppSelector(
        selectVehicleApplicationClicked
    );

    const { translate } = useTranslate();
    const searchedValue = useAppSelector(selectSearchValue);
    const selectedproductfilters = useAppSelector(selectProductFilters);

    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

    const [showAll, setShowAll] = useState({
        makes: false,
        years: false,
        models: false,
        components: false,
        category: false,
        supplier: false,
    });
    const [expandedCategories, setExpandedCategories] = useState<{
        [key: string]: boolean;
    }>({});

    useEffect(() => {
        if (!isVehicleApplicationClicked) {
            dispatch(updateMultiSelectFilters(null));
            dispatch(updateisMultiSelectFiltersApplied(false));
        }
    }, [searchedValue]);
    useEffect(() => {
        setExpanded({});
        setShowAll({
            makes: false,
            years: false,
            models: false,
            components: false,
            category: false,
            supplier: false,
        });
        setExpandedCategories({});
        setData({
            make: {},
            model: {},
            year: {},
            component: {},
        });
        setSelectedValues({
            make: [],
            year: [],
            model: [],
            component: [],
            supplier: [],
            category: [],
        });
        setSearchedValueLabel({
            make: '',
            year: '',
            model: '',
            component: '',
            supplier: '',
            category: '',
        });
        setFilteredCategories(allCategoriesWithChildren);
    }, [totalItems, searchedValue]);
    const MultiSelectData = convertToMultiSelectComponentData(
        updatedFilterItems?.refineCatalogs
    );
    const makes = MultiSelectData.vehicles
        .map((v) => v.make)
        .sort((a, b) => a.localeCompare(b));
    const yearsSet = new Set<string>();
    MultiSelectData.vehicles.forEach((vehicle) => {
        vehicle.years.forEach((yearObj) => {
            yearsSet.add(yearObj.year);
        });
    });
    const allYears = Array.from(yearsSet).sort((a, b) => b.localeCompare(a));

    const modelsSet = new Set<string>();
    MultiSelectData.vehicles.forEach((vehicle) => {
        vehicle.years.forEach((yearObj) => {
            yearObj.models.forEach((modelObj) => {
                modelsSet.add(modelObj.model);
            });
        });
    });
    const allModels = Array.from(modelsSet).sort((a, b) => a.localeCompare(b));

    const extractComponents = (modelObj: any): string[] => modelObj.components;
    const extractModels = (yearObj: any): string[] =>
        yearObj.models.flatMap(extractComponents);
    const extractYears = (vehicle: any): string[] =>
        vehicle.years.flatMap(extractModels);
    const getAllComponents = (vehicles: any[]): Set<string> => {
        const componentsSet = new Set<string>();
        vehicles.forEach((vehicle) => {
            extractYears(vehicle).forEach((component) => {
                componentsSet.add(component);
            });
        });
        return componentsSet;
    };

    const componentsSet = getAllComponents(MultiSelectData.vehicles);

    const allComponents = Array.from(componentsSet).sort((a, b) =>
        a.localeCompare(b)
    );

    const allCategoriesWithChildren =
        updatedFilterItems?.childrenCatalogs
            ?.map((category) => ({
                label: category.label,
                items: category.items || [],
                code: category.code ?? '',
            }))
            .sort() ?? [];
    const allInitialCategoriesWithChildren =
        totalItems?.childrenCatalogs
            ?.map((category) => ({
                label: category.label,
                items: category.items || [],
                code: category.code ?? '',
            }))
            .sort() ?? [];

    const allSuppliers =
        Array.from(
            new Set(
                updatedFilterItems?.suppliers?.map((supplier) => supplier.label)
            )
        ).sort((a, b) => a.localeCompare(b)) ?? [];

    function countAllSuppliers(
        selectedSupplierValues: any,
        allSuppliers: string | string[]
    ) {
        if (!selectedSupplierValues || !Array.isArray(allSuppliers)) {
            return 0;
        }

        const count = selectedSupplierValues.filter((supplier: string) =>
            allSuppliers.includes(supplier)
        ).length;

        return count;
    }

    const [filteredCategories, setFilteredCategories] = useState(
        allCategoriesWithChildren
    );

    useEffect(() => {
        if (searchedValue) {
            searchedValueLabels(searchedValue);
        }
    }, [totalItems]);

    const toggleSection = (section: any) => {
        setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
    };
    const toggleShowMoreCategories = (label: string) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [label]: !prev[label],
        }));
    };
    const toggleShowMore = (section: keyof typeof showAll) => {
        setShowAll((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    const handleSelectAllClearAllButtonsForCategory = (
        Categorylabel: string,
        allCategoriesData: {
            label: string;
            items: any;
            code: string;
        }[],
        selectedValues: SelectedValues
    ) => {
        const parentCategoryDetails = findCategoryChildrenByCode(
            allCategoriesData,
            Categorylabel
        );

        const allChildCodes = parentCategoryDetails.items
            ? parentCategoryDetails.items.flatMap((item: any) =>
                  item.items && Array.isArray(item.items)
                      ? item.items.map((subItem: any) => subItem.code)
                      : [item.code]
              )
            : [];
        const allChildrenSelected = allChildCodes.every((childCode: string) =>
            selectedValues['category'].includes(childCode)
        );

        const noChildrenSelected = allChildCodes.every(
            (childCode: string) =>
                !selectedValues['category'].includes(childCode)
        );

        let selectionState;
        if (allChildCodes.length === 0) {
            selectionState = 'no Button';
        } else if (allChildrenSelected) {
            selectionState = 'allSelected';
        } else if (noChildrenSelected) {
            selectionState = 'noneSelected';
        } else {
            selectionState = 'someSelected';
        }

        return {
            selectionState,
        };
    };

    const handleSelectAllClearAllButtonsForRestSections = (
        type: 'make' | 'model' | 'year' | 'component' | 'supplier',
        allValues: any
    ) => {
        const allChildCodes = allValues;
        const allChildrenSelected = allChildCodes.every((childCode: string) =>
            selectedValues[type].includes(childCode)
        );

        const noChildrenSelected = allChildCodes.every(
            (childCode: string) => !selectedValues[type].includes(childCode)
        );

        let selectionState;
        if (allChildCodes.length === 0) {
            selectionState = 'no Button';
        } else if (allChildrenSelected) {
            selectionState = 'allSelected';
        } else if (noChildrenSelected) {
            selectionState = 'noneSelected';
        } else {
            selectionState = 'someSelected';
        }

        return {
            selectionState,
        };
    };

    const handleCategorySelection = (
        Categorylabel: string,
        select: boolean,
        allCategoriesData: {
            label: string;
            items: any;
            code: string;
        }[]
    ) => {
        const parentCategoryDetails = findCategoryChildrenByCode(
            allCategoriesData,
            Categorylabel
        );
        const allChildCodes = parentCategoryDetails.items
            ? parentCategoryDetails.items.flatMap((item: any) =>
                  item.items && Array.isArray(item.items)
                      ? item.items.map((subItem: any) => subItem.code)
                      : [item.code]
              )
            : [];

        setSelectedValues((prevState) => {
            let updatedCategoryCodes = select
                ? [
                      ...prevState.category,
                      ...(allChildCodes.length > 0
                          ? allChildCodes
                          : [parentCategoryDetails.code]),
                  ]
                : prevState.category.filter(
                      (code) => !allChildCodes.includes(code)
                  );
            updatedCategoryCodes = Array.from(new Set(updatedCategoryCodes));

            return {
                ...prevState,
                category: updatedCategoryCodes,
            };
        });
    };

    const handleResetsectionsSelection = (
        allValues: string[],
        select: boolean,
        type: 'make' | 'model' | 'year' | 'component' | 'supplier',
        getDetailsFunction?: (value: string, refineCatalogs: any) => any
    ) => {
        allValues.forEach(async (value: string) => {
            const isValueSelected = selectedValues[type]?.includes(value);
            if ((select && !isValueSelected) || (!select && isValueSelected)) {
                await handleItemClick(type, value, getDetailsFunction);
            }
        });
    };

    const extractLastLabels = (items: any[]) => {
        let labels: any[] = [];
        items.forEach(
            (item: { items: string | any[]; label: any; code: string }) => {
                if (item.items && item.items.length > 0) {
                    if (Array.isArray(item.items)) {
                        labels = labels.concat(extractLastLabels(item.items));
                    }
                } else {
                    labels.push(item.code);
                }
            }
        );
        return labels;
    };

    const parentCategory = findParentCategory(
        allCategoriesWithChildren,
        searchedValue ?? ''
    );
    useEffect(() => {
        if (parentCategory) {
            setSearchedValueLabel((prevState: any) => ({
                ...prevState,
                category: parentCategory.label,
            }));
            setFilteredCategories([parentCategory]);
        } else {
            setFilteredCategories(allCategoriesWithChildren);
        }
    }, [updatedFilterItems]);

    const searchedValueLabels = (searchedValue: string | null) => {
        if (searchedValue != null && searchedValue != undefined) {
            const parentMake = makes.find(
                (make: string) => make === searchedValue
            );
            const parentYear = allYears.find(
                (year: string) => year === searchedValue
            );
            const parentModel = allModels.find(
                (model: string) => model === searchedValue
            );
            const parentComponent = allComponents.find(
                (component: string) => component === searchedValue
            );
            const parentSupplier = allSuppliers.find(
                (supplier: string) => supplier === searchedValue
            );

            if (parentCategory) {
                setSearchedValueLabel((prevState: any) => ({
                    ...prevState,
                    category: parentCategory.label,
                }));
                setFilteredCategories([parentCategory]);
            } else if (parentSupplier) {
                setSelectedValues((prevState) => ({
                    ...prevState,
                    supplier: [...prevState.supplier, searchedValue],
                }));
            } else if (
                parentMake ||
                parentYear ||
                parentModel ||
                parentComponent
            ) {
                setSelectedValues((prevState) => {
                    let updatedValues = {
                        make: [...prevState.make],
                        year: [...prevState.year],
                        model: [...prevState.model],
                        component: [...prevState.component],
                    };

                    if (selectedproductfilters?.Makes.length) {
                        updatedValues.make.push(
                            ...selectedproductfilters.Makes
                        );
                    }

                    if (selectedproductfilters?.Years.length) {
                        updatedValues.year.push(
                            ...selectedproductfilters.Years
                        );
                    }

                    if (selectedproductfilters?.Models.length) {
                        updatedValues.model.push(
                            ...selectedproductfilters.Models
                        );
                    }
                    if (selectedproductfilters?.Components.length) {
                        updatedValues.component.push(
                            ...selectedproductfilters.Components
                        );
                    } else if (parentMake) {
                        updatedValues.make.push(parentMake);
                    } else if (parentYear) {
                        updatedValues.year.push(parentYear);
                    } else if (parentModel) {
                        updatedValues.model.push(parentModel);
                    } else if (parentComponent) {
                        updatedValues.component.push(parentComponent);
                    }

                    updatedValues = {
                        make: Array.from(new Set(updatedValues.make)),
                        year: Array.from(new Set(updatedValues.year)),
                        model: Array.from(new Set(updatedValues.model)),
                        component: Array.from(new Set(updatedValues.component)),
                    };

                    return { ...prevState, ...updatedValues };
                });
            }

            if (parentCategory) {
                setSearchedValueLabel((prevState: any) => ({
                    ...prevState,
                    category: parentCategory.label,
                }));
                setFilteredCategories([parentCategory]);
            }

            if (parentSupplier) {
                setSelectedValues((prevState) => ({
                    ...prevState,
                    supplier: [...prevState.supplier, searchedValue],
                }));
            }
        }
    };

    const handleItemClick: (
        type: 'make' | 'model' | 'year' | 'component' | 'supplier' | 'category',
        value: string,
        getDetailsFunction?: (value: string, refineCatalogs: any) => any,
        parentValue?: string
    ) => Promise<void> = async (
        type: 'make' | 'model' | 'year' | 'component' | 'supplier' | 'category',
        value: string,
        getDetailsFunction?: (value: string, refineCatalogs: any) => any,
        parentValue?: string
    ) => {
        const isSelected = selectedValues[type].includes(value);
        setSelectedValues((prevSelectedValues) => {
            const isSelected = prevSelectedValues[type]?.includes(value);
            const updatedSelectedValues = {
                ...prevSelectedValues,
                [type]: isSelected
                    ? prevSelectedValues[type].filter((item) => item !== value)
                    : [...prevSelectedValues[type], value],
            };

            if (type === 'category') {
                const { selectionState } =
                    handleSelectAllClearAllButtonsForCategory(
                        parentValue ?? '',
                        allCategoriesWithChildren,
                        updatedSelectedValues
                    );
                if (selectionState === 'allSelected') {
                    handleCategorySelection(
                        parentValue ?? '',
                        true,
                        allInitialCategoriesWithChildren
                    );
                } else if (selectionState === 'noneSelected') {
                    handleCategorySelection(
                        parentValue ?? '',
                        false,
                        allInitialCategoriesWithChildren
                    );
                }
            }

            return updatedSelectedValues;
        });
        if (getDetailsFunction && !isSelected) {
            const details = getDetailsFunction(
                value,
                updatedFilterItems?.refineCatalogs
            );

            setData((prevData: any) => ({
                make: {
                    ...prevData.make,
                    ...(type !== 'make' && {
                        [value]: details.makes,
                    }),
                },
                model: {
                    ...prevData.model,
                    ...(type !== 'model' && {
                        [value]: details.models,
                    }),
                },
                year: {
                    ...prevData.year,
                    ...(type !== 'year' && {
                        [value]: details.years,
                    }),
                },
                component: {
                    ...prevData.component,

                    ...(type !== 'component' && {
                        [value]: details.components,
                    }),
                },
            }));
        } else {
            setData((prevData: any) => {
                const { [value]: removedMake, ...remainingMake } =
                    prevData.make;
                const { [value]: removedModel, ...remainingModels } =
                    prevData.model;
                const { [value]: removedYear, ...remainingYears } =
                    prevData.year;
                const { [value]: removedComponent, ...remainingComponents } =
                    prevData.component;

                return {
                    make: remainingMake,
                    model: remainingModels,
                    year: remainingYears,
                    component: remainingComponents,
                };
            });
        }
    };

    function getProductFilterOptionStyles(
        data: VehicleData,
        selectedValues: any,
        dataValue: any,
        itemDataObject: any
    ) {
        const isEmpty = Object.values(data).every(
            (value) => Object.keys(value).length === 0
        );

        const isSelected = Object.values(selectedValues).some(
            (categoryValues: any) => categoryValues.includes(dataValue)
        );
        const isDataTypeIncluded = Object.values(itemDataObject).some(
            (makes: any) => makes.includes(dataValue)
        );

        let backgroundColor = 'lightgrey';
        let isDisabled = true;

        if (isSelected) {
            backgroundColor = '#066FEF';
            isDisabled = false;
        } else if (
            isDataTypeIncluded ||
            isEmpty ||
            Object.keys(itemDataObject).length == 0
        ) {
            backgroundColor = 'white';
            isDisabled = false;
        }

        return { backgroundColor, isDisabled, isSelected };
    }

    return (
        <>
            <Box>
                {makes.length > 0 && (
                    <CollapsibleSection
                        title={translate(
                            `facetedSearch.labels.facetname_makes`
                        )}
                        sectionData={makes}
                        expanded={expanded}
                        toggleSection={toggleSection}
                        handleClick={(item: string) =>
                            handleItemClick('make', item, getDetailsByMake)
                        }
                        getProductFilterOptionStyles={
                            getProductFilterOptionStyles
                        }
                        showAll={showAll}
                        toggleShowMore={() => toggleShowMore('makes')}
                        sectionKey="makes"
                        sectionMappedData={data.make}
                        completeMappedFiltersData={data}
                        selectedValues={selectedValues}
                        selectedSectionLength={selectedValues.make.length}
                        handleSelectAllClearAllButtonsForRestSections={(
                            data: string[]
                        ) =>
                            handleSelectAllClearAllButtonsForRestSections(
                                'make',
                                data
                            )
                        }
                        handleResetsectionsSelection={(
                            select: boolean,
                            data: string[]
                        ) =>
                            handleResetsectionsSelection(
                                data,
                                select,
                                'make',
                                getDetailsByMake
                            )
                        }
                    />
                )}
                {allYears.length > 0 && (
                    <CollapsibleSection
                        title={translate(`facetedSearch.labels.facetname_year`)}
                        sectionData={allYears}
                        expanded={expanded}
                        toggleSection={toggleSection}
                        handleClick={(item: string) =>
                            handleItemClick('year', item, getDetailsByYear)
                        }
                        getProductFilterOptionStyles={
                            getProductFilterOptionStyles
                        }
                        showAll={showAll}
                        toggleShowMore={() => toggleShowMore('years')}
                        sectionKey="years"
                        sectionMappedData={data.year}
                        completeMappedFiltersData={data}
                        selectedValues={selectedValues}
                        selectedSectionLength={selectedValues.year.length}
                        handleSelectAllClearAllButtonsForRestSections={(
                            data: string[]
                        ) =>
                            handleSelectAllClearAllButtonsForRestSections(
                                'year',
                                data
                            )
                        }
                        handleResetsectionsSelection={(
                            select: boolean,
                            data: string[]
                        ) =>
                            handleResetsectionsSelection(
                                data,
                                select,
                                'year',
                                getDetailsByYear
                            )
                        }
                    />
                )}
                {allModels.length > 0 && (
                    <CollapsibleSection
                        title={translate(
                            `facetedSearch.labels.facetname_models`
                        )}
                        sectionData={allModels}
                        expanded={expanded}
                        toggleSection={toggleSection}
                        handleClick={(item: string) =>
                            handleItemClick('model', item, getDetailsByModel)
                        }
                        getProductFilterOptionStyles={
                            getProductFilterOptionStyles
                        }
                        showAll={showAll}
                        toggleShowMore={() => toggleShowMore('models')}
                        sectionKey="models"
                        sectionMappedData={data.model}
                        completeMappedFiltersData={data}
                        selectedValues={selectedValues}
                        selectedSectionLength={selectedValues.model.length}
                        handleSelectAllClearAllButtonsForRestSections={(
                            data: string[]
                        ) =>
                            handleSelectAllClearAllButtonsForRestSections(
                                'model',
                                data
                            )
                        }
                        handleResetsectionsSelection={(
                            select: boolean,
                            data: string[]
                        ) =>
                            handleResetsectionsSelection(
                                data,
                                select,
                                'model',
                                getDetailsByModel
                            )
                        }
                    />
                )}
                {allComponents.length > 0 && (
                    <CollapsibleSection
                        title={'Components'}
                        sectionData={allComponents}
                        expanded={expanded}
                        toggleSection={toggleSection}
                        handleClick={(item: string) =>
                            handleItemClick(
                                'component',
                                item,
                                getDetailsByComponent
                            )
                        }
                        getProductFilterOptionStyles={
                            getProductFilterOptionStyles
                        }
                        showAll={showAll}
                        toggleShowMore={() => toggleShowMore('components')}
                        sectionKey="components"
                        sectionMappedData={data.component}
                        completeMappedFiltersData={data}
                        selectedValues={selectedValues}
                        selectedSectionLength={selectedValues.component.length}
                        handleSelectAllClearAllButtonsForRestSections={(
                            data: string[]
                        ) =>
                            handleSelectAllClearAllButtonsForRestSections(
                                'component',
                                data
                            )
                        }
                        handleResetsectionsSelection={(
                            select: boolean,
                            data: string[]
                        ) =>
                            handleResetsectionsSelection(
                                data,
                                select,
                                'component',
                                getDetailsByComponent
                            )
                        }
                    />
                )}

                {allCategoriesWithChildren.length > 0 && (
                    <Box
                        sx={{
                            marginTop: '10px',
                        }}
                    >
                        <CategorySection
                            selectedValues={selectedValues}
                            expanded={expanded}
                            toggleSection={toggleSection}
                            handleItemClick={handleItemClick}
                            items={filteredCategories}
                            handleCategorySelection={(
                                value: string,
                                selection: boolean
                            ) =>
                                handleCategorySelection(
                                    value,
                                    selection,
                                    allInitialCategoriesWithChildren
                                )
                            }
                            handleSelectAllClearAllButtons={(value: string) =>
                                handleSelectAllClearAllButtonsForCategory(
                                    value,
                                    filteredCategories,
                                    selectedValues
                                )
                            }
                            translate={translate}
                            expandedCategories={expandedCategories}
                            toggleShowMoreCategories={toggleShowMoreCategories}
                        />
                    </Box>
                )}

                {allSuppliers.length > 0 && (
                    <Box
                        style={{
                            position: 'relative',
                            width: '100%',
                            borderRadius: '4px',
                            textAlign: 'center',
                            border: '1px solid gray',
                            marginTop: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '5px 10px',
                            cursor: 'pointer',
                            textTransform: 'none',
                            wordBreak: 'break-word',
                            backgroundColor:
                                countAllSuppliers(
                                    selectedValues['supplier'],
                                    allSuppliers
                                ) > 0
                                    ? '#066FEF'
                                    : 'white',
                            color: getColor(
                                countAllSuppliers(
                                    selectedValues['supplier'],
                                    allSuppliers
                                ) > 0
                            ),
                        }}
                        onClick={() => toggleSection('supplier')}
                    >
                        {countAllSuppliers(
                            selectedValues['supplier'],
                            allSuppliers
                        ) > 0 && (
                            <Typography
                                sx={{
                                    border: '1px solid',
                                    borderRadius: '50%',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                }}
                            >
                                {countAllSuppliers(
                                    selectedValues['supplier'],
                                    allSuppliers
                                )}
                            </Typography>
                        )}

                        <Typography id={'supplierMenuOption_Suppliers'}>
                            {translate(`ltl_SupplierFacetText`)}
                        </Typography>
                        <FontAwesomeIcon
                            icon={
                                expanded.supplier ? faChevronUp : faChevronDown
                            }
                        />
                    </Box>
                )}

                {(allSuppliers?.length ?? 0) > 0 && expanded.supplier && (
                    <Box
                        style={{
                            marginTop: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: '10px',
                                marginLeft: 'auto',
                                gap: '10px',
                                flexWrap: 'wrap',
                            }}
                        >
                            {(() => {
                                const { selectionState } =
                                    handleSelectAllClearAllButtonsForRestSections(
                                        'supplier',
                                        allSuppliers
                                    );
                                if (selectionState === 'no Button') {
                                    return null;
                                }
                                if (selectionState === 'allSelected') {
                                    return (
                                        <ActionButton
                                            onClick={() =>
                                                handleResetsectionsSelection(
                                                    allSuppliers,
                                                    false,
                                                    'supplier'
                                                )
                                            }
                                            buttonType="clearAll"
                                        >
                                            {translate(`ltl_ClearAllText`)}{' '}
                                        </ActionButton>
                                    );
                                } else if (selectionState === 'someSelected') {
                                    return (
                                        <>
                                            <ActionButton
                                                onClick={() =>
                                                    handleResetsectionsSelection(
                                                        allSuppliers,
                                                        false,
                                                        'supplier'
                                                    )
                                                }
                                                buttonType="clearAll"
                                            >
                                                {translate(`ltl_ClearAllText`)}{' '}
                                            </ActionButton>
                                            <ActionButton
                                                onClick={() =>
                                                    handleResetsectionsSelection(
                                                        allSuppliers,
                                                        true,
                                                        'supplier'
                                                    )
                                                }
                                                buttonType="selectAll"
                                            >
                                                {translate('selectAll_txt')}
                                            </ActionButton>
                                        </>
                                    );
                                } else if (selectionState === 'noneSelected') {
                                    return (
                                        <ActionButton
                                            onClick={() =>
                                                handleResetsectionsSelection(
                                                    allSuppliers,
                                                    true,
                                                    'supplier'
                                                )
                                            }
                                            buttonType="selectAll"
                                        >
                                            {translate('selectAll_txt')}
                                        </ActionButton>
                                    );
                                }
                            })()}
                        </Box>
                        {showAll.supplier ? (
                            <Box
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '10px',
                                    maxHeight: '400px',
                                    overflowY: 'auto',
                                    padding: '10px',
                                }}
                            >
                                {allSuppliers.map((supplier) => (
                                    <Button
                                        key={supplier}
                                        id={`supplierFilterBtn_${supplier.replaceAll(
                                            ' ',
                                            ''
                                        )}`}
                                        style={{
                                            border: '1px solid gray',
                                            borderRadius: '4px',
                                            padding: '5px 10px',
                                            width: 'auto',
                                            textAlign: 'center',
                                            wordBreak: 'break-word',
                                            backgroundColor: selectedValues[
                                                'supplier'
                                            ].includes(String(supplier))
                                                ? '#066FEF'
                                                : 'white',
                                            cursor: 'pointer',
                                            textTransform: 'none',
                                            color: getColor(
                                                selectedValues[
                                                    'supplier'
                                                ].includes(String(supplier))
                                            ),
                                        }}
                                        startIcon={
                                            selectedValues['supplier'].includes(
                                                supplier
                                            ) ? (
                                                <FontAwesomeIcon
                                                    icon={faRemove}
                                                    size="xs"
                                                    style={{ fontSize: '10px' }}
                                                />
                                            ) : null
                                        }
                                        onClick={() =>
                                            handleItemClick(
                                                'supplier',
                                                supplier
                                            )
                                        }
                                    >
                                        {supplier}
                                    </Button>
                                ))}
                            </Box>
                        ) : (
                            <Box
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '10px',
                                    padding: '0',
                                }}
                            >
                                {allSuppliers.slice(0, 6).map((supplier) => (
                                    <Button
                                        key={supplier}
                                        id={`supplierFilterBtn_${supplier.replaceAll(
                                            ' ',
                                            ''
                                        )}`}
                                        style={{
                                            border: '1px solid gray',
                                            borderRadius: '4px',
                                            padding: '5px 10px',
                                            width: 'auto',
                                            textAlign: 'center',
                                            wordBreak: 'break-word',
                                            backgroundColor: selectedValues[
                                                'supplier'
                                            ].includes(supplier)
                                                ? '#066FEF'
                                                : 'white',
                                            cursor: 'pointer',
                                            textTransform: 'none',
                                            color: getColor(
                                                selectedValues[
                                                    'supplier'
                                                ].includes(supplier)
                                            ),
                                        }}
                                        startIcon={
                                            selectedValues['supplier'].includes(
                                                supplier
                                            ) ? (
                                                <FontAwesomeIcon
                                                    icon={faRemove}
                                                    size="xs"
                                                    style={{ fontSize: '10px' }}
                                                />
                                            ) : null
                                        }
                                        onClick={() =>
                                            handleItemClick(
                                                'supplier',
                                                supplier
                                            )
                                        }
                                    >
                                        {supplier}
                                    </Button>
                                ))}
                            </Box>
                        )}
                    </Box>
                )}

                {allSuppliers.length > 6 && expanded.supplier && (
                    <Button
                        onClick={() => toggleShowMore('supplier')}
                        style={{
                            marginTop: 1,
                            fontSize: '10px',
                            fontWeight: '900',
                            color: blueColor,
                        }}
                    >
                        {showAll.supplier
                            ? translate(`less_txt`)
                            : translate(`more_txt`)}
                    </Button>
                )}
            </Box>
            <Box>
                <Button
                    style={{
                        marginTop: '20px',
                        fontSize: '10px',
                        fontWeight: '900',
                        marginRight: 'auto',
                        textTransform: 'capitalize',
                        color: 'white',
                        backgroundColor: 'rgb(14, 22, 100)',
                        padding: '5px',
                        borderRadius: 5,
                    }}
                    id="allFilterSection_applyButton"
                    onClick={() => {
                        handleApplyFiltersButton();
                    }}
                >
                    {translate('apply_txt')}
                </Button>
            </Box>
        </>
    );
};
