import { useEffect, useRef, useState } from 'react';
import { Box, Button, Slider, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faRemove,
} from '@fortawesome/free-solid-svg-icons';
import { useSliderItemsData } from './sliderItemsData';
import {
    SelectedValues,
    SubFiltersMenu,
    updateFilters,
    VehicleData,
} from './AdditionalFiltersMenuComponent';
import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks';
import {
    selectSearchValue,
    selectVehicleApplicationClicked,
    updateisMultiSelectFiltersApplied,
    updateMultiSelectFilters,
} from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice';
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
const updateCategoryValues = (
    prevSelectedValues: SelectedValues,
    item: string
): SelectedValues => {
    return Object.keys(prevSelectedValues).reduce((acc, category) => {
        const key = category as keyof SelectedValues;
        acc[key] = prevSelectedValues[key].filter((value) => value !== item);
        return acc;
    }, {} as SelectedValues);
};
const blueColor = 'rgb(14, 22, 100) !important';
export const getColor = (value: boolean) =>
    value ? 'white' : 'rgb(33, 37, 41)';

const MultiSelectList = ({
    totalSearchItems,
    updatedFilterItems,
}: {
    totalSearchItems: IItemListResponse | undefined;
    updatedFilterItems: IItemListResponse | undefined;
}) => {
    const [isPriceRangeSelected, setIsPriceRangeSelected] =
        useState<boolean>(true);
    const [priceRangeValues, setPriceRangeValues] = useState<any>([
        -1, 1000000,
    ]);
    const [visibility, setVisibility] = useState({
        showSlider: false,
        showMake: false,
        showYear: false,
        showModel: false,
        showComponent: false,
        showCategory: false,
        showSupplier: false,
    });

    type searchedValue = {
        make: '';
        year: '';
        model: '';
        component: '';
        supplier: '';
        category: '';
    };

    const [selectedValues, setSelectedValues] = useState<SelectedValues>({
        make: [],
        year: [],
        model: [],
        component: [],
        supplier: [],
        category: [],
    });
    const [searchedValueLabel, setSearchedValueLabel] = useState<searchedValue>(
        {
            make: '',
            year: '',
            model: '',
            component: '',
            supplier: '',
            category: '',
        }
    );
    const [data, setData] = useState<VehicleData>({
        make: {},
        model: {},
        year: {},
        component: {},
    });

    const [previousMinPrice, setPreviousMinPrice] = useState<number | null>(
        totalSearchItems?.minPrice ?? null
    );
    const [previousMaxPrice, setPreviousMaxPrice] = useState<number | null>(
        totalSearchItems?.maxPrice ?? null
    );

    useEffect(() => {
        setPreviousMinPrice(totalSearchItems?.minPrice ?? null);
        setPreviousMaxPrice(totalSearchItems?.maxPrice ?? null);
    }, [totalSearchItems]);
    const {
        minPrice,
        maxPrice,
        onSaleProducts,
        newProducts,
        callForPricingProducts,
    } = useSliderItemsData(
        updatedFilterItems,
        isPriceRangeSelected,
        totalSearchItems,
        setPreviousMaxPrice,
        setPreviousMinPrice,
        previousMaxPrice,
        previousMinPrice
    );
    const [isOnSaleClicked, setIsOnSaleClicked] = useState<boolean>(false);
    const [isOnSaleDisabled, setIsOnSaleDisabled] = useState<boolean>(false);
    const [isNewDisabled, setIsNewDisabled] = useState<boolean>(false);
    const [isNewClicked, setIsNewClicked] = useState<boolean>(false);
    const isVehicleApplicationClicked = useAppSelector(
        selectVehicleApplicationClicked
    );
    const { translate } = useTranslate();
    const [isCallForPricingClicked, setIsCallForPricingClicked] =
        useState<boolean>(false);
    const dispatch = useDispatch();
    const [price, setPrice] = useState<number[]>([
        minPrice ?? -1,
        maxPrice ?? 1000000,
    ]);
    const [finalPrice, setFinalPrice] = useState<number[]>([
        priceRangeValues[0] ?? -1,
        priceRangeValues[1] ?? 1000000,
    ]);
    const handleSaleClick = () => {
        setIsPriceRangeSelected(false);
        setIsOnSaleClicked(!isOnSaleClicked);
    };

    const initialAllCategoriesWithChildren =
        totalSearchItems?.childrenCatalogs?.map((category) => ({
            label: category.label,
            items: category.items || [],
            code: category.code ?? '',
        })) ?? [];
    const updatedCategoriesWithChildren =
        updatedFilterItems?.childrenCatalogs?.map((category) => ({
            label: category.label,
            items: category.items || [],
            code: category.code ?? '',
        })) ?? [];
    const updatedallSuppliers =
        updatedFilterItems?.suppliers?.map((supplier) => supplier.label) ?? [];
    const handleNewClick = () => {
        setIsPriceRangeSelected(false);
        setIsNewClicked(!isNewClicked);
    };
    const handleCallForPricingClick = () => {
        setIsPriceRangeSelected(false);
        setIsCallForPricingClicked(!isCallForPricingClicked);
    };

    const toggleVisibility = (key: keyof typeof visibility) => {
        setVisibility((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const isSaleActiveFromProducts = isOnSaleClicked && !isOnSaleDisabled;
    const isNewActiveFromProducts = isNewClicked && !isNewDisabled;
    const filterTranslations = [
        {
            condition: isSaleActiveFromProducts,
            translationKey: 'On_Sale_FacetText',
        },
        {
            condition: isNewActiveFromProducts,
            translationKey: 'CMSNewText',
        },
        { condition: isCallForPricingClicked, translationKey: 'MissingPrice' },
    ];

    const additionalFilters = filterTranslations
        .filter(({ condition }) => condition)
        .map(({ translationKey }) => translate(translationKey));
    const extractDeepestCodes = (category: {
        label: string;
        items: any;
        code: string;
    }): string[] => {
        if (!category.items || category.items.length === 0) {
            return category.code ? [category.code] : [];
        }

        return category.items.flatMap(extractDeepestCodes);
    };

    const extractAllDeepestCodes = (
        categories: {
            label: string;
            items: any[];
            code: string;
        }[]
    ): string[] => {
        return categories.flatMap(extractDeepestCodes);
    };

    const categoryCodes = extractAllDeepestCodes(updatedCategoriesWithChildren);

    const intitalAllSelectedItems = [
        ...Object.values(selectedValues.category).flat(),
        ...Object.values(selectedValues.supplier).flat(),
    ];
    const intersectedCategoryCodes = intitalAllSelectedItems.filter(
        (item) =>
            categoryCodes.includes(item) || updatedallSuppliers.includes(item)
    );

    const allSelectedItems = [
        ...additionalFilters,
        ...intersectedCategoryCodes,
        ...selectedValues.make,
        ...selectedValues.year,
        ...selectedValues.model,
        ...selectedValues.component,
    ];
    const previousSelectedValues = useRef(allSelectedItems);
    const searchedValue = useAppSelector(selectSearchValue);
    useEffect(() => {
        if (
            Object.values(selectedValues).reduce(
                (acc, curr) => acc + curr.length,
                0
            ) === 0 &&
            !isCallForPricingClicked &&
            !isOnSaleClicked &&
            !isNewClicked
        ) {
            setIsPriceRangeSelected(true);
        }
    }, [selectedValues]);
    useEffect(() => {
        setIsOnSaleClicked(false);
        setIsNewClicked(false);
        setIsCallForPricingClicked(false);
        setIsOnSaleDisabled(false);
        setIsNewDisabled(false);
        setPrice([-1, 1000000]);
        setFinalPrice([-1, 1000000]);
        setIsPriceRangeSelected(true);
        setPriceRangeValues([-1, 1000000]);
        if (searchedValue === 'On Sale') {
            setIsOnSaleClicked(true);
            setIsOnSaleDisabled(true);
        } else if (searchedValue === 'NewProducts') {
            setIsNewClicked(true);
            setIsNewDisabled(true);
        }
    }, [totalSearchItems, searchedValue]);

    const handleSelectedValueClick = (item: string) => {
        if (item === translate('MissingPrice')) {
            handleCallForPricingClick();
        }
        if (item === translate('CMSNewText')) {
            handleNewClick();
        }
        if (item === translate('On_Sale_FacetText')) {
            handleSaleClick();
        }

        setSelectedValues((prevSelectedValues) => {
            return updateCategoryValues(prevSelectedValues, item);
        });
        setData((prevData: any) => {
            const { [item]: removedMake, ...remainingMake } = prevData.make;
            const { [item]: removedModel, ...remainingModels } = prevData.model;
            const { [item]: removedYear, ...remainingYears } = prevData.year;
            const { [item]: removedComponent, ...remainingComponents } =
                prevData.component;

            return {
                make: remainingMake,
                model: remainingModels,
                year: remainingYears,
                component: remainingComponents,
            };
        });
    };

    const handleApplyFiltersButton = () => {
        const selectedValuesChanged =
            JSON.stringify(previousSelectedValues.current) !==
            JSON.stringify(allSelectedItems);

        setIsPriceRangeSelected(!selectedValuesChanged);

        if (
            Object.values(selectedValues).reduce(
                (acc, curr) => acc + curr.length,
                0
            ) === 0 &&
            !isCallForPricingClicked &&
            !isOnSaleClicked &&
            !isNewClicked
        ) {
            setIsPriceRangeSelected(true);
            setPreviousMaxPrice(totalSearchItems?.maxPrice ?? null);
            setPreviousMinPrice(totalSearchItems?.minPrice ?? null);
        }
        previousSelectedValues.current = allSelectedItems;

        updateFilters(
            {
                dispatchFilters: dispatch,
                onSale: isOnSaleClicked,
                newProduct: isNewClicked,
                priceRange: finalPrice,
                allCategories: initialAllCategoriesWithChildren,
                newSelectedValues: selectedValues,
                callForPricing: isCallForPricingClicked,
                updatedFilterItems: updatedFilterItems,
                isPriceRangeSelected: isPriceRangeSelected,
                priceRangeValues: priceRangeValues,
                searchedLabel: searchedValueLabel,
            },
            !!isVehicleApplicationClicked
        );
    };

    const [showAllSelectedValues, setShowAllSelectedValues] = useState(false);
    const handleClearAllSelectedValues = () => {
        setSelectedValues({
            make: [],
            year: [],
            model: [],
            component: [],
            supplier: [],
            category: [],
        });
        setData({
            make: {},
            model: {},
            year: {},
            component: {},
        });
        !isOnSaleDisabled && setIsOnSaleClicked(false);
        !isNewDisabled && setIsNewClicked(false);
        setIsCallForPricingClicked(false);
        dispatch(updateMultiSelectFilters(null));
        dispatch(updateisMultiSelectFiltersApplied(false));
        setShowAllSelectedValues(false);
        setPrice([-1, 1000000]);
        setFinalPrice([-1, 1000000]);
        setIsPriceRangeSelected(true);
        setPriceRangeValues([-1, 1000000]);
        setPreviousMaxPrice(totalSearchItems?.maxPrice ?? null);
        setPreviousMinPrice(totalSearchItems?.minPrice ?? null);
        previousSelectedValues.current = [];
    };
    const handleToggleClick = () => {
        setShowAllSelectedValues((prevState) => !prevState);
    };
    const renderItems = (items: any[]) => {
        const itemsToDisplay = showAllSelectedValues
            ? items
            : items.slice(0, 6);
        return (
            <>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '10px',
                            margin: '10px 0',
                            maxHeight: showAllSelectedValues ? '400px' : 'auto',
                            overflowY: showAllSelectedValues
                                ? 'auto'
                                : 'hidden',
                        }}
                    >
                        {itemsToDisplay.map((item, index) => {
                            return (
                                <Button
                                    key={item}
                                    className="chip-button"
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        padding: '5px 10px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        color: 'white',
                                        backgroundColor: '#066FEF',
                                        textTransform: 'none',
                                        display: 'inline-block',
                                        width: 'auto',
                                    }}
                                    startIcon={
                                        <FontAwesomeIcon
                                            icon={faRemove}
                                            size="xs"
                                            style={{
                                                fontSize: '10px',
                                                display: 'inline-block',
                                            }}
                                        />
                                    }
                                    onClick={() =>
                                        handleSelectedValueClick(item)
                                    }
                                >
                                    {item.includes('_')
                                        ? item.split('_').pop()
                                        : item}
                                </Button>
                            );
                        })}
                    </Box>
                    {items.length > 6 && !showAllSelectedValues && (
                        <Button
                            style={{
                                marginTop: 1,
                                fontSize: '10px',
                                fontWeight: '900',
                                color: blueColor,
                                marginBottom: '5px',
                            }}
                            onClick={handleToggleClick}
                        >
                            {translate(`more_txt`)}
                        </Button>
                    )}

                    {showAllSelectedValues && items.length > 6 && (
                        <Button
                            style={{
                                marginTop: 1,
                                fontSize: '10px',
                                fontWeight: '900',
                                color: blueColor,
                                marginBottom: '5px',
                            }}
                            onClick={handleToggleClick}
                        >
                            {translate(`less_txt`)}
                        </Button>
                    )}
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    {items.length > 0 && (
                        <Button
                            style={{
                                marginTop: 1,
                                fontSize: '10px',
                                fontWeight: '900',
                                color: blueColor,
                                borderColor: blueColor,
                                border: '1px solid',
                                padding: '5px',
                                textTransform: 'capitalize',
                                borderRadius: 5,
                            }}
                            onClick={handleClearAllSelectedValues}
                        >
                            {translate(`ltl_ClearAllText`)}
                        </Button>
                    )}
                    <Button
                        style={{
                            fontSize: '10px',
                            fontWeight: '900',
                            textTransform: 'capitalize',
                            color: 'white',
                            backgroundColor: 'rgb(14, 22, 100)',
                            padding: '5px',
                            borderRadius: 5,
                        }}
                        id="selectedFiltersApplySection_applyButton"
                        onClick={() => handleApplyFiltersButton()}
                    >
                        {translate('apply_txt')}
                    </Button>
                </Box>
            </>
        );
    };
    const totalSelectedCount = allSelectedItems.length;

    return (
        <Box style={{ padding: 2, marginTop: '1rem' }}>
            {totalSelectedCount > 0 && (
                <Box
                    sx={{
                        padding: '5%',
                        border: '1px solid',
                        marginBottom: '10%',
                        borderRadius: '5px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{ color: blueColor }}>
                            {translate('selected_filters_txt')}
                        </Typography>
                    </Box>
                    {renderItems(allSelectedItems)}
                </Box>
            )}

            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {onSaleProducts && !isOnSaleDisabled && (
                    <Button
                        style={{
                            width: '40%',
                            borderRadius: 5,
                            marginRight: '10px',
                            textAlign: 'center',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            backgroundColor: isOnSaleClicked
                                ? '#066FEF'
                                : 'white',
                            textTransform: 'none',
                            color: getColor(isOnSaleClicked),
                        }}
                        id="onSaleFilterBtn"
                        onClick={handleSaleClick}
                        startIcon={
                            isOnSaleClicked ? (
                                <FontAwesomeIcon
                                    icon={faRemove}
                                    size="xs"
                                    style={{
                                        fontSize: '10px',
                                        marginLeft: '5px',
                                    }}
                                />
                            ) : null
                        }
                    >
                        {translate(`On_Sale_FacetText`)}
                    </Button>
                )}
                {newProducts && !isNewDisabled && (
                    <Button
                        onClick={() => handleNewClick()}
                        style={{
                            width: '40%',
                            borderRadius: 5,
                            marginRight: '10px',
                            textAlign: 'center',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            backgroundColor: isNewClicked ? '#066FEF' : 'white',
                            textTransform: 'none',
                            color: getColor(isNewClicked),
                        }}
                        id="newFilterBtn"
                        startIcon={
                            isNewClicked ? (
                                <FontAwesomeIcon
                                    icon={faRemove}
                                    size="xs"
                                    style={{
                                        fontSize: '10px',
                                        marginLeft: '5px',
                                    }}
                                />
                            ) : null
                        }
                    >
                        {translate(`CMSNewText`)}
                    </Button>
                )}
            </Box>

            {minPrice != null && maxPrice != null && (
                <Box
                    style={{
                        position: 'relative',
                        width: '100%',
                        borderRadius: '5px',
                        textAlign: 'center',
                        border: '1px solid gray',
                        marginTop: '15px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '5px 10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => toggleVisibility('showSlider')}
                >
                    <Typography id={'priceOption_Price'}>
                        {translate(`ltl_PriceFacetText`)}
                    </Typography>
                    {visibility.showSlider ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                    )}
                </Box>
            )}
            {visibility.showSlider && minPrice != null && maxPrice != null && (
                <Box>
                    {!isCallForPricingClicked && (
                        <Box
                            style={{
                                marginTop: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                width: 'auto',
                                padding: '15px 15px 0',
                            }}
                        >
                            <Typography
                                style={{ textAlign: 'center', padding: '5px' }}
                            >{`${
                                totalSearchItems?.items[0]?.price
                                    ?.currencySymbol
                                    ? totalSearchItems?.items[0]?.price
                                          ?.currencySymbol
                                    : '$'
                            }${
                                Number.isInteger(minPrice)
                                    ? minPrice?.toFixed(0)
                                    : minPrice?.toFixed(2)
                            }`}</Typography>
                            <Slider
                                value={price}
                                onChange={(
                                    event: Event,
                                    newValue: number | number[]
                                ) => {
                                    if (
                                        Array.isArray(newValue) &&
                                        (newValue[0] !== finalPrice[0] ||
                                            newValue[1] !== finalPrice[1])
                                    ) {
                                        setPrice(newValue);
                                    }
                                }}
                                onChangeCommitted={(
                                    event: Event,
                                    newValue: number | number[]
                                ) => {
                                    if (
                                        Array.isArray(newValue) &&
                                        (newValue[0] !== finalPrice[0] ||
                                            newValue[1] !== finalPrice[1])
                                    ) {
                                        setFinalPrice(newValue);
                                        setPriceRangeValues([
                                            minPrice,
                                            maxPrice,
                                        ]);
                                    }
                                }}
                                valueLabelDisplay="on"
                                min={Number(minPrice?.toFixed(2))}
                                max={Number(maxPrice?.toFixed(2))}
                                style={{ flex: 1 }}
                                valueLabelFormat={(value: any) => `$${value}`}
                            />
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    marginLeft: '5px',
                                }}
                            >{`${
                                totalSearchItems?.items[0]?.price
                                    ?.currencySymbol
                                    ? totalSearchItems?.items[0]?.price
                                          ?.currencySymbol
                                    : '$'
                            }${
                                Number.isInteger(maxPrice)
                                    ? maxPrice?.toFixed(0)
                                    : maxPrice?.toFixed(2)
                            }`}</Typography>
                        </Box>
                    )}
                    <Box>
                        {callForPricingProducts && (
                            <Button
                                onClick={() => handleCallForPricingClick()}
                                style={{
                                    border: '1px solid',
                                    textTransform: 'none',
                                    width: '150px',
                                    backgroundColor: isCallForPricingClicked
                                        ? '#066FEF'
                                        : 'white',
                                    borderRadius: 5,
                                    marginTop: 10,
                                    color: getColor(isCallForPricingClicked),
                                }}
                                id="callForPricingFilterBtn"
                                startIcon={
                                    isCallForPricingClicked ? (
                                        <FontAwesomeIcon
                                            icon={faRemove}
                                            size="xs"
                                            style={{ fontSize: '10px' }}
                                        />
                                    ) : null
                                }
                            >
                                {translate(`MissingPrice`)}
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
            <SubFiltersMenu
                totalItems={totalSearchItems}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                updatedFilterItems={updatedFilterItems}
                handleApplyFiltersButton={handleApplyFiltersButton}
                data={data}
                setData={setData}
                setSearchedValueLabel={setSearchedValueLabel}
            />
        </Box>
    );
};

export default MultiSelectList;
