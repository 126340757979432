import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as S from "./Styles";
import { useTranslate } from "srs.sharedcomponents/lib/esm/hooks/useTranslate";
import { FormInputDropdown } from "srs.sharedcomponents/lib/esm/components/srs.formInputDropdown.component";
import { useAppSelector , useAppDispatch} from "srs.sharedcomponents/lib/esm/redux/hooks";
import { getProductRefineCatalogs } from "srs.sharedcomponents/lib/esm/redux/slices/facetedProductListSlice";
import { IDropDownOption } from "srs.sharedcomponents/lib/esm/models/srs.formDropdownOption.model";
import { getRefineCatalogs } from "srs.sharedcomponents/lib/esm/redux/slices/productlistSlice";
import {
  setLoadBreadCrumbLocally,
  setBreadCrumbData,
} from 'srs.sharedcomponents/lib/esm/redux/slices/breadCrumbSlice';
import { IBreadCrumbResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.breadCrumbResponse.model';
interface IFormSchema {
  buttons: string;
  frequency: string;
  type: string;
  keyCodeRange: string;
  export: string;
}
interface IFacetedSearchProps {
  isKeyFinder?: boolean;
  onSelectionChange: (selected: { key: keyof IFormSchema; value: string }[]) => void;
  setRefineYourSearchValues: React.Dispatch<React.SetStateAction<any>>;
  refineYourSearchValues: any[] | null
  searchText: any
  makeModelYear?: string | null
}
const transformApiResponseToFacetOptions = (data: any) => {
  const keyFinderRefineSearch = data;

  const refineSearchOrder = [
    'KeyFOB Buttons',
    'KeyFrequency',
    'KeyType',
    'Key Code Range',
    'Export'
  ];

  const mapFacetToDropdown = (facetLabel: string) => {
    const facet = keyFinderRefineSearch.find((facet: any) => facet.label === facetLabel);
    if (!facet) return { level: '', options: [] };

    let levelName = facet.label;
    
    // Update facet labels as per the required changes
    switch (facet.label) {
      case 'KeyFOB Buttons':
        levelName = 'Number of Buttons';
        break;
      case 'KeyFrequency':
        levelName = 'Frequency';
        break;
      case 'KeyType':
        levelName = 'Type';
        break;
      case 'Key Code Range':
        levelName = 'Keycode Range';
        break;
      case 'Export':
        levelName = 'Export/Domestic';
        break;
      default:
        break;
    }

    const options = [
      { label: `${levelName}`, value: '' },
      ...(facet.items || []).map((item: any) => ({
        label: item.label,
        value: item.label,
      })),
    ];

    return { level: levelName, options };
  };

  const dynamicRefineSearchValue = refineSearchOrder.map((facetLabel: string) => {
    return mapFacetToDropdown(facetLabel);
  });

  const returnRefineSearch = () => {
    const refinedFacets = dynamicRefineSearchValue.filter((facet: any) => facet.options.length > 1);
    return refinedFacets.reduce((acc: any, facet: any) => {
      acc[facet.level.toLowerCase().replace(/\s+/g, '')] = facet;
      return acc;
    }, {});
  };

  return returnRefineSearch();
};

const RefineYourSearch: React.FC<IFacetedSearchProps> = ({ isKeyFinder, onSelectionChange, refineYourSearchValues, 
  setRefineYourSearchValues, searchText, makeModelYear }) => {
  const [facetOptions, setFacetOptions] = useState<Record<string, { level: string; options: IDropDownOption[] }>>();
  const dispatch = useAppDispatch();
  const showBreadCrumb = (name:any) => {
    const breadCrumb: IBreadCrumbResponse = {
      child: {
      child:null,
      culture: 1,
      displaySequence: 1,
      id: name,
      name: name,
      parentID: null,
      url: '',
      },
      culture: 1,
      displaySequence: 1,
      id: name,
      name: 'Key Finder',
      parentID: 'Key Finder',
      url: '',
    }
    dispatch(setLoadBreadCrumbLocally(true))
    dispatch(setBreadCrumbData(breadCrumb))
  }
  const { translate } = useTranslate();
  const refineCatalogs = useAppSelector(getRefineCatalogs)
  const refineProductCatalogs = useAppSelector(getProductRefineCatalogs)
  showBreadCrumb(searchText);
  const schema = yup.object().shape({
    buttons: yup.string(),
    frequency: yup.string(),
    type: yup.string(),
    keyCodeRange: yup.string(),
    export:yup.string()
  });

  useEffect(()=>{
    setRefineYourSearchValues(refineYourSearchValues)
  },[refineYourSearchValues])

  const { control, watch, handleSubmit } = useForm<IFormSchema>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      buttons: '',
      frequency: '',
      type: '',
      keyCodeRange: '',
      export:''
    },
  });

  const onSubmit = (data: IFormSchema) => {
    setRefineYourSearchValues(Object.values(data).filter(value => value !== "" && value !== undefined))
    onSelectionChange(Object.values(data).filter(value => value !== "" && value !== undefined));
  };

  const getRefineYourSearchCatalogOptions = () => {
    if(refineCatalogs?.length > 0){
      return refineCatalogs
    }
    else if(refineProductCatalogs?.length > 0){
      return refineProductCatalogs
    }
    return []
  }

  useEffect(()=>{
    return ()=>{
      setFacetOptions({})
    }
  },[])

  useEffect(() => {
    if(refineYourSearchValues && refineYourSearchValues.length === 0){
      let options = getRefineYourSearchCatalogOptions()
      const refinedOptions = transformApiResponseToFacetOptions(options);
      setFacetOptions(refinedOptions);
    }
  }, [refineCatalogs, refineProductCatalogs]);


  if (!facetOptions) {
    return null;
  }

  return (
    <div>
      <S.logo className="pb-3">{translate("keyFinder.refineyoursearch")}</S.logo>
      <S.searchText className="pb-3">{translate("keyFinder.yousearch")} {`'${searchText}'`} {makeModelYear ? `- ${makeModelYear}` : null}</S.searchText>
      <S.SelectsForm onSubmit={handleSubmit(onSubmit)} mainImage={false} isKeyFinder={isKeyFinder}>
        {Object.keys(facetOptions).map((key) => {
          const typedKey = key as keyof IFormSchema; // Cast key
          const field = facetOptions[key];
          return (
            <div key={key}>
              <FormInputDropdown
                id={key}
                name={key}
                label={field.level}
                options={field.options}
                selectedValue={watch(typedKey) || ''} 
                onChange={(e: { value: string }) => {
                  handleSubmit(onSubmit)()
                }}
                control={control}
                isKeyFinder={true}
              />
            </div>
          );
        })}
      </S.SelectsForm>
    </div>
  );
};

export default RefineYourSearch;